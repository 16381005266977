<template>
  <v-card
    elevation="2"
    id="chatbot-window"
    class="chat-window overflow-hidden"
    rounded="xxl"
  >
    <v-card-title
      class="blue pb-0 pt-5"
      style="
        z-index: 100;
        margin-top: -2px;
        position: relative;
        min-height: 10%;
      "
    >
      <v-avatar class="white mr-3 pa-3" size="48px">
        <v-img contain src="@/assets/robin/Curious.png" height="48px" />
      </v-avatar>
      <div class="d-flex flex-column align-start white--text">
        <span class="body-2">Chat with</span>
        <span class="text-h6">Robin</span>
      </div>
      <v-spacer />
      <v-btn icon medium @click="$emit('dismissed')">
        <v-icon medium color="white">mdi-chevron-down</v-icon>
      </v-btn>
      <!-- <img :src="Swoosh" /> -->
    </v-card-title>
    <swoosh style="width: 100%" color="#2196f3" />
    <chat
      ref="chat"
      @submit="submitted"
      :generating="Generating"
      :messages="messages"
      :form="form"
    />
    <div
      class="d-flex align-start mb-3"
      style="position: absolute; bottom: 0px; right: 8px"
    >
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!form.text"
        color="blue elevation-3"
        @click="submitted"
        fab
        small
      >
        <v-icon color="white"> mdi-send </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Chat from "./Chat.vue";
import Swoosh from "./ui/Swoosh.vue";
import axios from "axios";
export default {
  components: {
    Chat,
    Swoosh
  },
  computed: {
    Generating() {
      return this.generating;
    },
    Width() {
      if (!this.width) {
        const { amount, units } = /(?<amount>\d*)(?<units>[a-z]+)/gm.exec(
          this.size || this.height
        ).groups;
        console.log({ amount, units });
        return `${amount / 2}${units}`;
      } else return this.width;
    },
    Height() {
      return this.size || this.height;
    }
    // Swoosh() {
    //   return Swoosh;
    // },
  },
  watch: {
    sending: {
      deep: true,
      handler(n, o) {
        this.generating = Boolean(n.length);
        this.$nextTick(() => {
          this.$refs.chat.scrollToBottom();
        });
      }
    },
    messages: {
      deep: true,
      handler(n, o) {
        this.$nextTick(() => {
          this.$refs.chat.scrollToBottom();
        });
      }
    }
  },
  data() {
    return {
      generating: false,
      form: {
        text: "",
        new: true
      },
      messages: [],
      sending: []
    };
  },
  async created() {
    this.form.text = "Hi, how can you help?";
    this.form.silent = true;
    await this.submitted();
  },
  methods: {
    async submitted() {
      if (!this.form.text) return;
      let id = "" + Date.now() + Math.random();
      // setTimeout(() => {
      this.sending.push(id);
      // }, 500);
      const { text, silent } = this.form;
      try {
        !this.form.silent && this.messages.push({ text, kind: "sent", id });
        // await new Promise((r) => setTimeout(r, 5000));
        /**@todo generate */
        const fire = axios.post("/chat", this.form, {
          baseURL: process.env.VUE_APP_AI_URL,
          headers: {
            Authorization: "Bearer " + this.$store.state.token
          }
        });
        this.form.text = "";
        this.form.silent = false;
        this.form.new = false;
        const res = await fire;

        this.messages.push({
          text: res.data,
          kind: "received",
          id
        });
      } catch (e) {
        if (!silent) this.form.text = text;
        setTimeout(() => {
          this.messages.splice(
            this.messages.findIndex(i => i.id === id),
            1
          );
        }, 1000);
        /**@todo API call failure */
      } finally {
        this.sending.splice(
          this.sending.findIndex(i => i === id),
          1
        );
      }
    }
  },

  props: {
    height: {
      type: String,
      default: "512px"
    },
    width: {
      type: String,
      default: "320px"
    },
    size: {
      type: String
    }
  }
};
</script>
<style scoped>
.chat-window {
  /* height: 614px !important; */
  width: 384px !important;
  /* background-color: #2196f3; */
  border: 1px solid #2196f3 !important;
}
@media screen and (width < 720px) {
  .chat-window {
    width: 100vw !important;
    /* height: 100vh !important; */
    /* min-height: 300px !important; */
  }
}
@media screen and (height < 720px) {
  .chat-window {
    /* height: 100vh !important;
    min-height: 300px !important; */
    height: 100%;
    max-height: 100%;
  }
}
</style>
