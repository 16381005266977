<template>
  <div class="container-fluid">
    <v-row justify="center">
      <v-col cols="6">
        <!-- <ul class="text-left">
          <li
            v-for="company in Companies"
            :key="company.clientId"
            @click="displayCompany(company)"
          >
            <div>
              <div
                :class="
                  company.procore.company === input.backfill.companyId
                    ? 'red--text'
                    : ''
                "
                class="font-weight-bold"
              >
                {{ company.clientId }} - {{ company.clientName }}
              </div>
              <template
                v-if="company.procore.company === input.backfill.companyId"
              >
                <ul v-if="Projects && data.projects !== undefined">
                  <li
                    v-for="project in Projects"
                    :key="project.id"
                    @click="addProject(project)"
                  >
                    <div>
                      <div
                        :class="
                          input.backfill.projects.includes(project.id)
                            ? 'red--text'
                            : ''
                        "
                        class="font-italic"
                      >
                        {{ project.id }} - {{ project.name }}
                      </div>
                    </div>
                  </li>
                </ul>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </template>
            </div>
          </li>
        </ul> -->
        <v-autocomplete
          label="Company"
          :items="CompanyOptions"
          item-text="text"
          item-value="value"
          v-model="input.backfill.companyId"
        />
        <v-autocomplete
          v-if="input.backfill.companyId"
          label="Project"
          :items="ProjectOptions"
          item-text="text"
          item-value="value"
          v-model="input.backfill.projects"
          multiple
        />
      </v-col>
      <v-col cols="6">
        <v-form
          @submit.prevent="backfill"
          style="max-height: 80vh"
          class="overflow-auto"
        >
          <v-text-field
            v-model="dateRangeText"
            label="Backfill Date Range"
            prepend-icon="mdi-calendar"
            readonly
            range
          ></v-text-field>
          <v-date-picker v-model="input.backfill.dates" range></v-date-picker>

          <v-select
            label="Resource"
            item-text="text"
            item-value="value"
            v-model="input.backfill.resource"
            :items="ResourceOptions"
          />
          <!-- <pre class="text-left">
              {{ input.backfill }}
            </pre> -->
          <v-btn type="submit"> Backfill! </v-btn>
          <details
            v-for="(response, i) in data.responses"
            :key="i"
            class="text-left"
            :class="
              response.status >= 200 && response.status < 300
                ? 'green--text text--darken-3'
                : 'red--text text--darken-3'
            "
          >
            <summary>Click to view response #{{ i + 1 }}</summary>
            <pre class="grey lighten-2 overflow-auto">{{ response }}</pre>
          </details>
          <template v-if="data.responses.length">
            <div class="green--text" v-if="AllGood">All Good!</div>
            <div v-else>Ruh roh!</div>
          </template>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import RobinService from "@/services/RobinService";
import LoadingDialog from "@/components/loadingDialog.vue";
import axios from "axios";
import throttled from "../../util/throttled";
import fmtRes from "../../util/fmtRes";
import { mapState } from "vuex";
import PROVIDERS from "./fixtures/providers";
import moment from "moment";
import RESOURCES from "../../shared_data/procore-daily-log-resources.json";
/**
 * @type {Object.<string, {path:string, label?:string}>}
 */
const RESOURCE_INFO_MAP = /**@const */ {
  "Daily Logs": {
    path: "/methods/backfill-daily-logs"
  },
  Images: {
    label: "Photos",
    path: "/methods/backfill-images"
  },
  "Safety Violation Logs": {
    path: "/methods/backfill-safety-violations"
  }
};
export default {
  name: "IntegrationsManager",
  props: {},
  components: {
    // LoadingDialog,
  },
  watch: {
    "input.backfill.companyId"(n, v) {
      if (n !== v) {
        this.input.backfill.projects = [];
        // console.log(
        //   "K",
        //   this.input.backfill.companyId,
        //   company.procore.company
        // );
        this.data.projects = undefined;
        this.getCompanyProjects();
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "token"]),
    ProjectOptions() {
      return this.Projects.map(project => ({
        text: `Project#${project.id} - ${project.name}`,
        value: project.id
      }));
    },
    CompanyOptions() {
      return this.Companies.map(company => ({
        text: `Client#${company.clientId} Company#${company.procore.company} - ${company.clientName}`,
        value: company.procore.company
      }));
    },

    dateRangeText() {
      return this.input.backfill.dates.join(" ~ ");
    },
    ResourceOptions() {
      return [
        { text: "Daily Logs", value: "Daily Logs" },
        ...RESOURCES.map(r => ({ text: r, value: r }))
      ];
    },
    Companies() {
      // alert("wat");
      if (!this.data.companies) {
        // alert("hwo");
        this.getClientsCompanies({
          limit: 9999
        });
      }
      return this.data.companies || [];
    },
    Projects() {
      if (!this.input.backfill.companyId) return [];
      if (!this.data.projects) {
        this.getCompanyProjects();
      }
      return this.data.projects || [];
    },
    AllGood() {
      return (
        this.data.responses &&
        this.data.responses.every(r => r.status >= 200 && r.status < 300)
      );
    }
  },
  async mounted() {
    // process.env.VUE_APP_PROCORE_URL
    // const statusResult = await axios.get(
    //   process.env.VUE_APP_PROCORE_URL + "/status/client-company/9",
    //   {
    //     headers: {
    //       Authorization: "Bearer " + this.$store.state.token,
    //     },
    //   }
    // );
    // console.log("Status result:", statusResult);
  },

  data() {
    return {
      data: {
        companies: undefined,
        projects: undefined,
        responses: []
      },
      cache: {},
      input: {
        /**
         * @type {{
         *  dates: Date[],
         *  resource: keyof typeof RESOURCE_INFO_MAP
         * }}
         */
        backfill: {
          dates: [
            new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
            new Date()
          ].map(s => s.toISOString()),
          resource: "Images",
          projects: [],
          companyId: null
        }
      },
      RESOURCE_INFO_MAP
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    displayCompany(company) {
      if (this.input.backfill.companyId === company.procore.company) return;
      this.input.backfill.companyId = company.procore.company;
      this.input.backfill.projects = [];
      console.log("K", this.input.backfill.companyId, company.procore.company);
      this.data.projects = undefined;
    },
    addProject(project) {
      this.input.backfill.projects.push(project.id);
    },
    // async finchConnected(result) {
    // },
    ...throttled({
      async getClientsCompanies(opts) {
        const { skip, limit } = opts || {};
        // alert("ku");
        if (this.data.companies !== undefined) return this.data.companies;
        // alert("HA");
        const companies = await axios.get(
          process.env.VUE_APP_PROCORE_URL + "/methods/get-clients-companies",
          {
            params: {
              skip: skip || 0,
              limit: limit || 10
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        );
        this.data.companies = companies.data;
      },
      async getCompanyProjects() {
        const cacheKey = `projects-${this.input.backfill.companyId}`;
        if (!this.input.backfill.companyId) return [];
        if (this.cache[cacheKey]) {
          this.data.projects = this.cache[cacheKey];
          return;
        }
        if (this.data.projects !== undefined) return this.data.projects;
        const projects = await axios.get(
          process.env.VUE_APP_PROCORE_URL + "/methods/get-company-projects",
          {
            params: {
              companyId: this.input.backfill.companyId
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        );
        this.data.projects = projects.data;
        this.cache[cacheKey] = this.data.projects;
      },
      async backfill() {
        const cfg = this.input.backfill;
        let date = moment(cfg.dates[0]);
        const end = moment(cfg.dates[1]),
          _dates = [];
        while (date.diff(end, "days") < 0) {
          _dates.push(date.format("YYYY-MM-DD"));
          date = date.add(12, "weeks").add(6, "days");
        }
        _dates.push(end.format("YYYY-MM-DD"));
        console.log("_Dates:", _dates);
        const responses = [];

        if (!(cfg.project instanceof Array)) cfg.project = [cfg.project];
        for (const project of cfg.projects) {
          console.log("Project", project);
          const dates = _dates.slice();
          while (dates.length > 1) {
            const start = dates.shift();
            const end = dates[0];
            console.log("POSTING");
            const res = await axios.post(
              process.env.VUE_APP_PROCORE_URL +
                `/methods/v2/backfill/${this.input.backfill.resource}`,
              {
                start,
                end,
                company: cfg.companyId,
                project
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token
                },
                validateStatus: () => true
              }
            );
            responses.push(fmtRes(res, false));
          }
        }
        console.log(responses, responses.length);
        this.data.responses = responses;
      }
    })
  }
};
</script>

<style scoped>
.client-dropdown {
  width: 350px;
}
</style>
<style lang="css" scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}
</style>
